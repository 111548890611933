import React, { useEffect } from 'react'
import "./About.css"

const About = () => {
    useEffect(() =>{
        window.scroll(0, 0);
    });
    return (
        <> 
            < section className = "about-hero" > 
                <div className="container">
                    <div className="row align-items-lg-center mr-top">
                        <div className="col-12 col-sm-12 col-lg-6 mb-3 text-left d-flex align-items-center">
                            <h1 className="text-white">
                                About
                            </h1>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-6 d-lg-block mb-3 text-center mt-3">
                            <div className="banner-img">
                                <img
                                    src="https://cdn.ivaluetv.com/public/gcp247/Revised-ASPL-Info-Map-2048x1308.png"
                                    alt="Contact Us"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-contact">
                <div className="container">
                    <div className="row align-items-lg-center">

                        <div className="col-md-12">
                            <div className="info-box">
                                <p>ASPL Info – an iValue Group Company, is a technology enterprise revolutionizing businesses with best-in-class IT services and digital transformations.</p>
                                <p>Via our network of partners across continents, we drive success for global enterprises, blue-chip giants, ambitious mid-market businesses, and SMBs, anywhere, anytime.</p>
                                <p>Over the last decades, we have worked extensively with brands cutting across various sectors and geographies including HP, Titan, Karnataka Bank, Trust Bank, Tata Sky, William Penn, Bajaj, Maruti, Emirates, Marico, Lupin, and Dhanalaxmi Bank.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="info-box-img">
                                <img
                                    src="https://cdn.ivaluetv.com/public/gcp247/Revised-ASPL-Info-Map-2048x1308.png" alt="About Us"/>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <p>Team ASPL Info prides itself on transparency, integrity, commitment, and a hands-on collaborative experience. Clients, Partners, and OEMs across cultures and geographies see ASPL Info as a long-term strategic partner they trust for high-quality outcomes and solutions tailored to their needs. With ASPL Info, they experience excellence and surety.</p>
                                <p>We have partnered with enterprise tools such as Zabbix, Splunk, SummitAI, Elastic, and Jiffy.ai to provide need-based services over and above our traditional solutions.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Enterprise Tools</h3>
                            </div>
                            <div className="info-box-img">
                                <img src="https://cdn.ivaluetv.com/public/gcp247/Group-1.png" alt="About Us"/>
                            </div>
                            <div className="info-box">
                                <p>Our clients, big and small, across cultures and geographies see us as a long-term strategic partner who they trust for high-quality end-results and a consistent service experience. We meticulously follow our strong, dependable, and scalable processes. Our teams demonstrate in-depth domain knowledge, are hands-on, and passionate about getting the job done.</p>
                                <p>That is why our clients experience surety and excellence, every single time.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Our Value</h3>
                            </div>
                            <div className="info-box-img">
                                <img src="https://cdn.ivaluetv.com/public/gcp247/6-Values-without-logo-1536x706.jpg" alt="About Us"/>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Thoroughness in the DNA</h3>
                                <p>First, we understand our customer’s scenario and problem statement in-depth. Then we lay down processes meticulously and adhere to them with attention to detail. We are neither lazy in following systems nor afraid of doing the right thing. This is central to how we deliver quality, every single time. This is central to how we are growing fast but growing wisely.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Knowing beyond knowledge</h3>
                                <p>Driven by a hunger for precision and perfection, we gather domain knowledge from credible sources and stay updated. Then we go a step ahead because accumulating knowledge is not the same as knowing. We dig deeper. We analyse and reflect. Until we know. That’s what makes us competent. That’s what helps us deliver excellence.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Consistent and reliable customer experience</h3>
                                <p>Irrespective of our customer’s stature, for us each one is equal. Each one is precious. And each one deserves the best solution. So, whether there is an act of god or living in the new normal, we give our customers the comfort of a consistent experience. We lend surety.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Authentic people make an authentic organisation</h3>
                                <p>We are honest and transparent. We are genuine and trustworthy. We nurture relationships rooted in mutual respect. We weave dreams. Always striving to deliver the most relevant and best in breed services, we keep learning along the way and get better and better at all we do.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Passionate about getting the job done</h3>
                                <p>We are unrelenting solution-finders and tenacious team workers. If an outcome has to be delivered, however fast, however complex, we will go out of our way to do it without compromising on quality. Staying true to our commitments is extremely dear to us. We constantly find enterprising solutions and many times go the extra mile to give our clients a little more.</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="info-box">
                                <h3>Committed to creating equal opportunities</h3>
                                <p>We believe charity begins at home. Looking inwards, we are committed to being an inclusive organisation. We nurture a liberal and diverse culture where people who work with us have opportunities to achieve their life goals and career aspirations. Looking outwards, building a caring community is a core part of who we are. We help people in less privileged positions empowering them in different ways that go beyond extending monetary support. And we do it in a quiet and unassuming way.</p>
                            </div>
                        </div>

                        
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default About