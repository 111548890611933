import React from 'react';
import { useRouteError } from 'react-router-dom';
import './Error.css';

const Error = () => {
    const err = useRouteError();
    const errorMessage = err?.data || err?.message || "An unknown error occurred";
    return (
        <section className="error-section">
            <div className="container">
                <div className="row align-items-lg-center mr-top">
                <div className="col-12 col-lg-6 mb-3">
                    <h1 className="text-white">
                    We can't find the page you're looking for.
                    </h1>
                    <p className="text-white mb-0 prg-text">Error Is - {errorMessage}</p>
                </div>
                <div className="col-12 col-lg-6 mb-3 text-center">
                    <img src="https://cdn.ivaluetv.com/public/gcp247/404-error.svg" alt="Error" className="img-fluid" />
                </div>
                </div>
            </div>
        </section>
    );
}

export default Error;