import React, { useEffect } from 'react';
import "./Contact.css";

const Hero = () => {
  useEffect(() =>{
    window.scroll(0, 0);  
  },[]);
  return (
    <>
        <section className="contact-hero">
        <div className="container">
            <div className="row align-items-lg-center mr-top">
            <div className="col-12 col-sm-12 col-lg-6 mb-3 text-left d-flex align-items-center">
                <h1 className="text-white">
                Contact Us
                </h1>
            </div>
            <div className="col-12 col-sm-12 col-lg-6 d-lg-block mb-3 text-center mt-3">
                <div className="banner-img">
                    <img src="https://cdn.ivaluetv.com/public/gcp247/Revised-ASPL-Info-Map-2048x1308.png" alt="Contact Us" />
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="contact-contact">
        <div className="container">
          <div className="row align-items-lg-center">
            <div className="col-md-6 d-flex">
              <div className="info-box">
                <i className="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p><b>ASPL Info Services Pvt. Ltd.(Head Office India)</b>,903/1/1, 19th Main Road, Sector 4, HSR Layout, Bengaluru – 560102, INDIA</p>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="info-box">
                <i className="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p><b>ASPL Info Services FZE (UAE)</b>, L1-27 Executive Suite, PO Box – 121600, Saif-Zone, Sharjah, UAE.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info-box">
                <i className="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <p>info@asplinfo.com</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="info-box">
                <i className="bi bi-telephone"></i>
                <h3>Call</h3>
                <p>+91 63 6639 8088 , +97 15 0394 0151</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;