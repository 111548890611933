import React from 'react';
import "./Header.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-light">
      <div className="container-fluid">
        <img src="https://cdn.ivaluetv.com/public/sec360/sec3602.gif" alt="Google Cloud Logo" className="logo" />
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 p-3">
                <li><Link className="dropdown-item me-3" to="/">Home</Link></li>
                <li><Link className="dropdown-item me-3" to="/blog">Blog</Link></li>
                <li><Link className="dropdown-item me-3" to="/about">About</Link></li>   
                <li><Link className="dropdown-item me-3" to="/contact">Contact</Link></li>  
            </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;