import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer bg-light text-center text-lg-start">
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-2 col-md-6 mb-4 mb-md-0 d-flex flex-column align-items-center justify-content-center">
            <img src="https://cdn.ivaluetv.com/public/aspl.png" alt="aspl" className="img-fluid" />
          </div>
          <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Service</h5>
            <ul className="list-unstyled mb-0">
              <li><Link to="https://asplinfo.com/?stm_service=managed-security" className="text-dark" target='_blank'>Managed Security</Link></li>
              <li><Link to="https://asplinfo.com/?stm_service=managed-it-infra" className="text-dark" target='_blank'>Managed IT Infrastructure</Link></li>
              <li><Link to="https://asplinfo.com/?stm_service=managed-resources" className="text-dark" target='_blank'>Managed Resources</Link></li>
              <li><Link to="https://asplinfo.com/?stm_service=automation-and-grc" className="text-dark" target='_blank'>Automation and GRC</Link></li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Tools</h5>
            <ul className="list-unstyled mb-0">
              <li><Link to="https://asplinfo.com/zabbix/" className="text-dark" target='_blank'>Zabbix</Link></li>
              <li><Link to="https://asplinfo.com/splunk/" className="text-dark" target='_blank'>Splunk</Link></li>
              <li><Link to="https://asplinfo.com/symphony-summit/" className="text-dark" target='_blank'>Symphony Summit</Link></li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Resources</h5>
            <ul className="list-unstyled mb-0">
              <li>Documentation</li>
              <li>Support</li>
              <li>Pricing</li>
              <li>Training</li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Company</h5>
            <ul className="list-unstyled mb-0">
              <li><Link to="/about" className="text-dark">About Us</Link></li>
              <li><Link to="https://ivaluegroup.com/en-in/careers/working-at-ivalue/" className="text-dark" target='_blank'>Careers</Link></li>
              <li><Link to="https://asplinfo.com/blogs" className="text-dark" target='_blank'>Blog</Link></li>
              <li><Link to="/contact" className="text-dark">Contact Us</Link></li>
            </ul>
          </div>          
          <div className="col-lg-2 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Legal</h5>
            <ul className="list-unstyled mb-0">
              <li><Link to="https://asplinfo.com/privacy-policy/" className="text-dark" target='_blank'>Privacy Policy</Link></li>
              <li><Link to="https://asplinfo.com/privacy-policy/" className="text-dark" target='_blank'>Terms of Service</Link></li>
              <li><Link to="https://asplinfo.com/privacy-policy/" className="text-dark" target='_blank'>Cookie Policy</Link></li>
              <li><Link to="https://asplinfo.com/privacy-policy/" className="text-dark" target='_blank'>Security</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="endborder text-center p-3 text-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-md-start">
              © {new Date().getFullYear()} iValue Group
            </div>
            <div className="col-md-6 text-md-end">
              <Link to="https://www.facebook.com/asplinfo" className="text-white me-4" target='_blank'><i className="bi bi-facebook"></i></Link>
              <Link to="https://twitter.com/asplinfo" className="text-white me-4" target='_blank'><i className="bi bi-twitter"></i></Link>
              <Link to="https://www.linkedin.com/company/asplinfo" className="text-white me-4" target='_blank'><i className="bi bi-linkedin"></i></Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;