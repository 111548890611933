import React, { useEffect } from 'react';

const Body = () => {
    useEffect(() =>{
        window.scroll(0, 0);
    }, []);
    return (
        <div>
            <h1>Coming Soon</h1>
        </div>
    );
};

export default Body;