import React, { useEffect } from 'react';
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import Error from './components/Error/Error';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Body from './components/Body/Body';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Blog from './components/Blog/Blog';
import BlogContent from './components/BlogContent/BlogContent';

const App = () => {
    useEffect(() => {
        Aos.init({ 
            duration: 1000,
            disable: 'mobile'
        });
    },[]);
    return (
    <div className="app">
        <Header />
        <Outlet />
        <Footer />
    </div>
)};

const appRouter = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: (
            <div className="app">
                <Header />
                <Error />
                <Footer />
            </div>
        ),
        children: [
            {
                path: '/',
                element: <Body />
            },
            {
                path: '/about',
                element: <About />
            },
            {
                path: '/contact',
                element: <Contact />
            },{
                path: '/blog',
                element: <Blog />
            },
            {
                path: '/blog/:blogId',
                element: <BlogContent />
            }
        ]
    }
], {
    future: {
      v7_normalizeFormMethod: true,
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<RouterProvider router={appRouter} />)