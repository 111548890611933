import React from 'react';
import './ShimmerBlog.css';

const ShimmerBlog = () => {
  return (
    <>
            {[1, 2, 3, 4, 5].map((_, index) => (
              <div className="col-md-4" key={index}>
                <div className="card shimmer-card">
                  <div className="shimmer-img"></div>
                  <div className="card-body">
                    <div className="shimmer-title"></div>
                    <div className="shimmer-btn"></div>
                  </div>
                </div>
              </div>
            ))}
    </>
  );
};

export default ShimmerBlog;