import React from 'react'
import './ShimmerBlogContent.css'

const ShimmerBlogContent = () => {
  return (
    <>
        <section className="blog-content-section">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-12">
                    <div className="shimmer-wrapper">
                    <div className="shimmer-header-img"></div>
                    <div className="shimmer-title"></div>
                    <div className="shimmer-content"></div>
                    <div className="shimmer-content"></div>
                    <div className="shimmer-content"></div>
                    <div className="shimmer-meta"></div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ShimmerBlogContent;