import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { getBlogById } from '../../utils/apiEndpoint';
import { cdnImageURL } from '../../utils/static';
import ShimmerBlogContent from '../Shimmer/ShimmerBlogContent';
import './BlogContent.css';


const BlogContent = () => {
    const { blogId } = useParams();
    const navigate = useNavigate();
    const [getBlogDetails, setBlogDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        let isMounted = true;

        if (blogId) {
            fetchBlogData(blogId, isMounted);
        }

        return () => {
            isMounted = false;
        };
    }, [blogId]);

    const fetchBlogData = async (blogId, isMounted) => {
        const token = process.env.REACT_APP_API_TOKEN;
        try {
            const response = await fetch(getBlogById, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idKey: blogId })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.status === 'success' && isMounted) {
                setBlogDetails(data?.data[0]);
            } else {
                console.log('Failed to fetch data');
                setError(true);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setError(true);
        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (error) {
            navigate('/blog');
        }
    }, [error, navigate]);

    if (loading) {
        return <ShimmerBlogContent />;
    }
    
    return (
        <>
            <Helmet>
                <title>{getBlogDetails.title}</title>
                <meta name="description" content={getBlogDetails.title}  />
                <meta name="keywords" content="ivalue group" />
                <meta property="og:title" content={getBlogDetails.title} />
                <meta property="og:description" content={getBlogDetails.title} />
                <meta property="og:image" content={cdnImageURL+getBlogDetails.headerImg} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <section className="blog-content-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <img src={cdnImageURL+getBlogDetails.headerImg} alt={getBlogDetails.title} className="blog-header-img" />
                            <h1 className="blog-title">{getBlogDetails.title}</h1>
                            <div className="blog-content" dangerouslySetInnerHTML={{ __html: getBlogDetails.content }} />
                            <div className="blog-meta">
                                <p>Written by: {getBlogDetails.updated_by}</p>
                                <p>Created on: {new Date(getBlogDetails.created_at).toLocaleDateString()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogContent;