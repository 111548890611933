import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { cdnImageURL } from '../../utils/static';

import './Blog.css';
import ShimmerBlog from '../Shimmer/ShimmerBlog';
import {getAllBlogs} from '../../utils/apiEndpoint';

const Blog = () => {
    const [getBlog, setBlog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const token = process.env.REACT_APP_API_TOKEN;
        try {
            const response = await fetch(getAllBlogs, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            
            if(data.status === 'success'){
                setBlog(data.data);
            } else {
                console.log('Failed to fetch data');
                setError(true);
            }
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <section className="blog-section">
                <div className="container">
                    <div className="row justify-content-center">
                        {loading || error ? (
                            <ShimmerBlog />
                        ) : (
                            getBlog.map((blog, index) => (
                                <div className="col-md-4" key={index}>
                                    <div className="card">
                                        <img src={cdnImageURL + blog.headerImg} className="card-img-top" alt={blog.title} />
                                        <div className="card-body">
                                            <h5 className="card-title">{blog.title}</h5>
                                            <div className="text-end">
                                                <Link to={`/blog/${blog.idKey}`} className="btn btn-primary">Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog;